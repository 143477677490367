import { collection, onSnapshot } from 'firebase/firestore';
import { firestore } from '@/firebase';
import { Expense } from '@/types';

export const subscribeToExpenses = (
  { companyId }: { companyId: string },
  callback: (expenses: Expense[]) => void,
) => {
  const q = collection(firestore, 'c', companyId, 'expenses');

  return onSnapshot(q, (querySnapshot) => {
    const r: Expense[] = [];
    querySnapshot.forEach((doc) =>
      r.push({ ...doc.data(), id: doc.id } as Expense),
    );

    callback(r);
  });
};
