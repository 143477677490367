import { signal } from '@preact/signals';
import { Order, OrderStatus } from 'cosdb-types';

export const ordersSignal = signal<Order[]>([]);

export const getOrdersByProductId = (id: string, status: OrderStatus) =>
  ordersSignal.value.filter(
    (order) =>
      order.products.some((product) => product.id === id) &&
      order.status === status,
  );
