import { v4 as uuid } from 'uuid';
import { signal } from '@preact/signals';

import { Expense } from '@/types';

export const expensesSignal = signal<Expense[]>([]);

export const buildNewExpense = (): Expense => ({
  id: uuid(),
  notes: '',
  name: '',
});
