import { initializeApp } from 'firebase/app';
import { getFirestore, connectFirestoreEmulator } from 'firebase/firestore';
import { getAuth, connectAuthEmulator } from 'firebase/auth';
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions';
import { getMessaging, MessagePayload, onMessage } from 'firebase/messaging';
import { route } from 'preact-router';

const firebaseConfig = {
  apiKey: 'AIzaSyBUGMjWXDKs5FJXy0UeENLQyBumVQjkMr0',
  authDomain: 'cosdb2-c2d7c.firebaseapp.com',
  projectId: 'cosdb2-c2d7c',
  storageBucket: 'cosdb2-c2d7c.appspot.com',
  messagingSenderId: '329506444047',
  appId: '1:329506444047:web:aad84c5525fd93e94de47a',
  measurementId: 'G-LQS7B7LYF1',
};

export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const firestore = getFirestore(app);
export const messaging = getMessaging(app);
const functions = getFunctions(app);

if (process.env.PREACT_APP_EMULATORS === 'dev') {
  connectAuthEmulator(auth, 'http://127.0.0.1:9099');
  connectFirestoreEmulator(firestore, '127.0.0.1', 8085);
  connectFunctionsEmulator(functions, '127.0.0.1', 5001);
}

export const vapidKey =
  'BI3ULrvDBLcjGlkTLGxDSet-Qq1-P5AMaB9ix5TlrQQcNGE_yyXrdHytvNBNYPV1slzndXd_wz8o0ff4ojuLfjs';

onMessage(messaging, (payload: MessagePayload) => {
  if (payload.data?.messageType === 'BDAY') {
    const n = new Notification('День народження', {
      body: `Сьогодні день народження у ${payload.data.firstName.trim()} ${payload.data.lastName.trim()}!`,
    });

    n.onclick = () => route(`/clients/${payload.data?.id}`);
  }
});
