import { v4 as uuid } from 'uuid';
import { signal } from '@preact/signals';
import { Procedure } from '@/types';

export const proceduresSignal = signal<Procedure[]>([]);

export const buildNewProcedure = (): Procedure => ({
  name: '',
  description: '',
  price1: 0,
  price2: 0,
  duration: 0,
  deleted: false,
  id: uuid(),
});
