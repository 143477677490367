import './Loader.css';

const Loader = () => {
  return (
    <div class='Loader'>
      <div class='spinner-border text-secondary' role='status'>
        <span class='visually-hidden'>Завантаження...</span>
      </div>
    </div>
  );
};

export default Loader;
