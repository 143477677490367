import { collection, onSnapshot } from 'firebase/firestore';
import { firestore } from '@/firebase';
import { Client, FirestoreClient } from '@/types';

const clientFromFirestore = (client: FirestoreClient): Client => {
  const date = client.birthDate?.toDate();

  const birthDateDay = date?.getUTCDate();
  const birthDateMonth = date?.getUTCMonth();
  const birthDateYear = date?.getUTCFullYear();

  return {
    ...client,
    birthDateDay,
    birthDateMonth:
      birthDateMonth !== undefined ? birthDateMonth + 1 : undefined,
    birthDateYear,
  };
};

export const subscribeToClients = (
  { companyId }: { companyId: string },
  callback: (clients: Client[]) => void,
) => {
  const q = collection(firestore, 'c', companyId, 'clients');

  return onSnapshot(q, (querySnapshot) => {
    const r: Client[] = [];
    querySnapshot.forEach((doc) =>
      r.push({
        ...clientFromFirestore(doc.data() as FirestoreClient),
        id: doc.id,
      } as Client),
    );

    callback(r);
  });
};
