import { collection, onSnapshot } from 'firebase/firestore';
import { firestore } from '@/firebase';
import { Procedure } from '@/types';

export const subscribeToProcedures = (
  { companyId }: { companyId: string },
  callback: (procedures: Procedure[]) => void,
) => {
  const q = collection(firestore, 'c', companyId, 'procedures');

  return onSnapshot(q, (querySnapshot) => {
    const r: Procedure[] = [];
    querySnapshot.forEach((doc) =>
      r.push({ ...doc.data(), id: doc.id } as Procedure),
    );

    callback(r);
  });
};
