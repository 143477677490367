import { signal } from '@preact/signals';
import { v4 } from 'uuid';
import { getDate, getFullYear, getMonth } from '@/utils';
import { Sale } from 'cosdb-types';

export const salesSignal = signal<Sale[]>([]);

export const buildNewSale = (params: {
  [K in keyof Partial<Sale>]: string;
}): Sale => ({
  clientId: params.clientId || '',
  productId: params.productId || '',
  day: +(params.day || '') || getDate(),
  month: +(params.month || '') || getMonth(),
  year: +(params.year || '') || getFullYear(),
  inventory: {},
  price: +(params.price || '') || 0,
  id: v4(),
});
