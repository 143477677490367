import { collection, onSnapshot } from 'firebase/firestore';
import { firestore } from '@/firebase';
import { Product } from 'cosdb-types';

export const subscribeToProductsByCompanyId = (
  { companyId }: { companyId: string },
  callback: (products: Product[]) => void,
) => {
  const q = collection(firestore, 'c', companyId, 'products');

  return onSnapshot(q, (querySnapshot) => {
    const r: Product[] = [];
    querySnapshot.forEach((doc) =>
      r.push({ ...doc.data(), id: doc.id } as Product),
    );

    callback(r);
  });
};
