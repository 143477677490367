import { companySignal } from './company';
import { userSignal } from './user';

export const isAppInitialized = () => {
  return (
    userSignal.value !== null &&
    userSignal.value !== undefined &&
    companySignal.value !== undefined
  );
};
